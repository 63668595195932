<template>
  <!-- Start Component: organisms/tableau-embed -->

  <!-- Third Party Cookies Modal -->
  <div class="modal cookie-modal" :class="isModalActive">
    <div class="modal-content">
      <span class="close" @click="closeModal">&times;</span>
      <p class="cookie-modal-title">Third-party cookies are not currently enabled in your browser.</p>
      <p>Please enable third-party cookies for the best user experience of the Browse Contracts feature. Without third-party cookies you will not see the complete dashboard.</p>
      <div v-if="isChrome && !isMobile">
        <ol class="list">
          <li>Open Chrome.</li>
          <li>At the top right, click <strong>More</strong> and then <strong>Settings</strong>.</li>
          <li>Click Privacy and security and then Third-party cookies.</li>
          <li>Select <strong>Allow third-party cookies</strong>.</li>
        </ol>
        <p><strong>Having trouble with these instructions?</strong> You can find <a href="https://support.google.com/chrome/answer/95647?hl=en-GB&co=GENIE.Platform%3DDesktop#zippy=%2Callow-or-block-third-party-cookies" target="_blank">more detailed and up-to-date instructions here</a>.</p>
      </div>
      <div v-if="isMobile && isChrome">
        <ol class="list">
          <li>Open Chrome.</li>
          <li>At the top right, click More More and then Settings Settings.</li>
          <li>Click Privacy and security and then Third-party cookies.</li>
          <li>Select <strong>Allow third-party cookies</strong>.</li>
        </ol>
        <p><strong>Having trouble with these instructions?</strong> You can find <a href="https://support.google.com/chrome/answer/95647?hl=en-GB&co=GENIE.Platform%3DDesktop#zippy=%2Callow-or-block-third-party-cookies" target="_blank">more detailed and up-to-date instructions here</a>.</p>
      </div>

      <div v-if="isFirefox">
        <p>To turn off Enhanced Tracking Protection for a specific website:</p>
        <ol class="list">
          <li>Visit the website.</li>
          <li>Click on the shield icon to the left of the address bar</li>
          <li>Toggle the switch at the top of the panel. This will turn off Enhanced Tracking Protection for this site. The page will reload automatically and allow trackers on this site only.</li>
        </ol>
        <p><strong>Having trouble with these instructions?</strong> You can find <a href="https://support.mozilla.org/en-US/kb/third-party-cookies-firefox-tracking-protection" target="_blank">more detailed and up-to-date instructions here</a>.</p>
      </div>

      <div v-if="isEdge && !isMobile">
        <ol class="list">
          <li>
            <p>In the Edge window, select <strong>More (...)</strong> &gt; <strong>Settings</strong> &gt; <strong>View advanced settings</strong>.</p>
          </li>
          <li>
            <p>Scroll down to <strong>Cookies</strong>, and select <strong>Don't block cookies</strong></p>
          </li>
        </ol>
        <p><strong>Having trouble with these instructions?</strong> You can find <a href="https://support.microsoft.com/en-us/office/enable-cookies-6b018d22-1d24-43d9-8543-3d35ddb2cb52" target="_blank">more detailed and up-to-date instructions here</a>.</p>
      </div>
      <div v-if="isMobile && isEdge">
        <p><a href="https://support.microsoft.com/en-us/microsoft-edge/microsoft-edge-for-mobile-faqs-29296eab-b76f-4a87-ac9c-9835da53465d" target="_blank">Up-to-date, detailed instructions to enable third-party cookies can be found here</a>.</p>
      </div>

      <div v-if="isSafari && !isMobile">
        <ol class="list">
          <li>Open Safari, then click Safari in the top navigation bar and choose Preferences.</li>
          <li>Select the Privacy tab.</li>
          <li>Turn off <strong>Prevent Cross-site Tracking</strong> to block third-party cookies.</li>
        </ol>
        <p><strong>Having trouble with these instructions?</strong> You can find <a href="https://support.apple.com/en-is/guide/safari/sfri11471/16.0/mac/11.0" target="_blank">more detailed and up-to-date instructions here</a>.</p>
      </div>
      <div v-if="isMobile && isSafari">
        <ol class="list">
          <li>Go to Settings App.</li>
          <li>Scroll down and tap Safari in the Settings menu.</li>
          <li>Scroll down to the Privacy &amp; Security section.</li>
          <li>Toggle off <strong>Prevent Cross-Site Tracking</strong> to enable third-party cookies.</li>
        </ol>
        <p><strong>Having trouble with these instructions?</strong> You can find <a href="https://support.apple.com/en-is/guide/iphone/iphb01fc3c85/ios" target="_blank">more detailed and up-to-date instructions here</a>.</p>
      </div>

      <div v-if="isOpera">
        <ol class="list">
          <li>Open Opera and click the three horizontal bars in the top right corner to open the Easy Setup menu.</li>
          <li>Scroll down and click Go to Full Browser Settings.</li>
          <li>Click the Privacy &amp; Security tab, then click Cookies and Other Site Data.</li>
          <li>Choose <br><strong>Allow all cookies</strong> to enable all cookies in Opera, including third-party cookies.</li>
        </ol>
        <p><strong>Having trouble with these instructions?</strong> You can find <a href="https://allaboutcookies.org/how-to-manage-cookies" target="_blank">more detailed and up-to-date instructions here</a>.</p>
      </div>

      <div v-if="!isOpera && !isSafari && !isEdge && !isFirefox && !isChrome">
        <a href="https://allaboutcookies.org/how-to-manage-cookies" target="_blank">Up-to-date, detailed instructions to enable third-party cookies for all major browsers can be found here.</a>
      </div>
    </div>
  </div>

  <div class="tableau-embed-wrapper" id="tableau-embed-wrapper">
    <a class="reset-link" v-if="!loading" @click="resetTableauView">Reset filters</a>
    <div id="tableauViz"</div>
    <preloader v-if="loading" class="custom-preloader"></preloader>
  </div>
  <!-- End Component: organisms/tableau-embed -->
</template>

<script>
  import Preloader from "./preloader";
  import RequestHandler from "@/handler/RequestHandler"
  import { onMounted, ref, computed, watchEffect } from "vue";
  import { TableauViz, TableauEventType } from '@tableau/embedding-api';

  export default {
    name: 'TableauEmbed',
    props: [
      'viewType',
      'viewNamesBreakpoints',
      'public',
      'user',
      'addDivider',
      'height'
    ],
    components: {
      Preloader,
    },
    methods: {
      closeModal() {
        const cookieModal = document.querySelector('.cookie-modal');
        if (cookieModal) {
          cookieModal.remove();
        }
      }
    },
    data() {
      return {
        showModal: false
      };
    },
    setup(props) {
      window.handleLoadError = function (err) {
          console.error(err);
      };

      const isModalActive = ref('');

      const loading = ref(true);

      const initTableau = async (token) => {
        // Check the current device width and select the appropriate view
        const currentWidth = window.innerWidth;
        const breakpoints = Object.keys(props.viewNamesBreakpoints).map(Number);

        // Sort breakpoints in ascending order
        breakpoints.sort((a, b) => a - b);

        let selectedView = props.viewNamesBreakpoints[breakpoints[0]];

        for (const breakpoint of breakpoints) {
          if (currentWidth >= breakpoint) {
            selectedView = props.viewNamesBreakpoints[breakpoint];
          } else {
            break;
          }
        }

        const viz = new TableauViz();
        viz.toolbar = 'hidden';
        viz.src = "https://prod-useast-b.online.tableau.com/t/mygovguide/views/" + props.viewType + "/" + selectedView;
        viz.style="overflow-y: hidden;";
        viz.token = token;
        viz.addEventListener(TableauEventType.FirstInteractive, handleFirstInteractive);
        document.getElementById('tableauViz').appendChild(viz);
      };

      const handleFirstInteractive = () => {
        loading.value = false;
      };

      onMounted(() => {
        if (props.public) {
          RequestHandler.loadTableauJwtPublic().then(token => {
            initTableau(token);
          }).catch((error) => {
            console.error(error);
          });

          let iframeResizerScript = document.createElement('script')
          iframeResizerScript.setAttribute('src', '/tableau/iframeResizer.contentWindow.min.js')
          document.head.appendChild(iframeResizerScript)
        }
        else {
          RequestHandler.loadTableauJwt().then(token => {
            initTableau(token);
          }).catch((error) => {
            console.error(error);
          });
        }
      });

      onMounted(() => {
        const receiveMessage = function (evt) {
          if (evt.data === 'MM:3PCunsupported') {
            isModalActive.value = 'is-active';
            console.log('WARNING: Third-party cookies are NOT enabled.');
          } else if (evt.data === 'MM:3PCsupported') {
            isModalActive.value = '';
            console.log('SUCCESS: Third-party cookies are enabled.');
          }
        };
        window.addEventListener("message", receiveMessage, false);

        const iframe = document.createElement('iframe');
        iframe.src = "https://mindmup.github.io/3rdpartycookiecheck/start.html";
        iframe.style.display = "none";
        document.body.appendChild(iframe);

        // Set up a timeout to handle cases where no recognized event comes back after a while
        setTimeout(function () {
          console.log('Timeout: third party cookies are not supported or there was an issue connecting to Tableau.');
        }, 5000); // Adjust the timeout duration as needed

      });

      const userAgent = ref(window.navigator.userAgent);
      const isMobile = ref(false);
      const isChrome = ref(false);
      const isFirefox = ref(false);
      const isEdge = ref(false);
      const isSafari = ref(false);
      const isOpera = ref(false);

      onMounted(() => {
        isMobile.value = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent.value);
        isChrome.value = /Chrome/.test(userAgent.value) && !/Edge/.test(userAgent.value);
        isFirefox.value = /Firefox/.test(userAgent.value);
        isEdge.value = /Edg/.test(userAgent.value); // Edge user agent contains "Edg"
        isSafari.value = /Safari/.test(userAgent.value) && !/Chrome/.test(userAgent.value);
        isOpera.value = /OPR/.test(userAgent.value);
      });

      const resetTableauView = (e) => {
        e.preventDefault();
        // just reload the iframe
        const iframe = document.querySelector('tableau-viz');
        if (iframe) {
          iframe.src = iframe.src + " ";
        }
      };

      return {
        loading,
        resetTableauView,
        isModalActive,
        userAgent,
        isChrome,
        isFirefox,
        isEdge,
        isSafari,
        isOpera,
        isMobile
      };
    },
  };
</script>

<style lang="scss">
  @import "../../assets/scss/_variables.scss";

  .modal {
    background-color: rgba(2,44,67,0.7);
  }

  .modal-content {
    background-color: $blue--lighter !important;
    color: $blue--dark !important;
    box-shadow: $box-shadow !important;
    border-radius: 8px;
    padding: 30px 15px 15px 15px;
    box-shadow: $box-shadow;
    .close {
      position: absolute;
      top: 0px;
      right: 10px;
      cursor: pointer;
      font-size: 24px;
    }
  }

  .cookie-modal-title {
    font-size: 24px;
    font-weight: bold;
    line-height: 1.25;
    margin-bottom: 20px;
  }

  .text-center {
    text-align: center;
  }

  ol.list, .list {
    padding-left: 22px;
    margin-bottom: 30px;
    li {
      margin-bottom: 5px;
    }
  }

  .horizontal-divider {
    border: 2px solid $primary;
    margin-top: 75px;
    margin-bottom: 75px;
    @media screen and (max-width: 768px) {
        margin-top: 25px;
        margin-bottom: 25px;
    }
  }

  #tableauViz {
    position: relative;
    width: 100%;
    height: 100%; /* Ensure it takes the full height of the parent */
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden; /* Prevent content overflow during initialization */
  }

  #tableau-embed-wrapper {
    position: relative; /* Ensure this wrapper is the positioning context */
    min-height: 250px;
    width: 100%; /* Ensure it spans the full width of its parent */
  }

  .custom-preloader {
    position: absolute; /* Make preloader overlay #tableauViz */
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 1);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10; /* Ensure it appears above the Tableau loader */
  }

  #tableauViz .tableauSpinner {
    display: none !important;
  }

</style>
