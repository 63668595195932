<template>

    <vendor-flyout
        ref="vendorFlyoutRef"
        v-bind="vendorProps"
    />

    <contract-flyout
        ref="contractFlyoutRef"
        v-bind="contractProps"
        :vendor="contractProps && contractProps.vendor && contractProps.vendor.VendorRef ? contractProps.vendor.VendorRef : null"
        :agency="contractProps && contractProps.agency && contractProps.agency.AgencyRef ? contractProps.agency.AgencyRef : null"
    ></contract-flyout>

    <br/>

    <breadcrumbs :items="path"/>

    <div class="container">
        <alert/>
    </div>

    <hr/>

    <div class="container dfw mobile-contain">
        <h1>Browse Contracts</h1>
    </div>

    <tableau-embed
        :addDivider=true
        :view-type="'SmallFloridaSpending'"
        :view-names-breakpoints="{
            0: 'FloridaSpending',
        }"
        height="1200px"
    />

    <div class="columns" v-if="!contractsInfoLoading">
        <div class="column is-full-mobile">
            <contract-pie-chart  :contracts="contractsInfo.countBySpend" title="Largest Vendors by spend" type="byVendorSpend"/>
        </div>
        <div class="column is-full-mobile">
            <contract-pie-chart  :contracts="contractsInfo.countByContracts" title="Largest Vendors by Number of Contracts" type="byVendorContracts"/>
        </div>
        <div class="column is-full-mobile">
            <contract-pie-chart  :contracts="contractsInfo.countByAgencies" title="Biggest Contracting Agencies" type="agencyId"/>
        </div>
    </div>
    <div class="procurements-loading" v-if="contractsInfoLoading">
        <Preloader />
    </div>

    <hr/>

    <div class="date-tabs">
        <div class="date-tabs-element">
            <contracts-table-date  title="Expiring Contracts" date="end" />
        </div>

        <div  class="date-tabs-element">
            <contracts-table-date  title="New Contracts" date="begin"/>
        </div>
    </div>

    <hr class="horizontal-divider"/>


    <contracts-table
        :uniqueId="uniqueId"
        :search-agency="true"
        :search-type="true"
        :search-domain="false"
        :subflyout="true"
        title="Contracts"
        :scrollable="true"
        :vendorshow="true"
    />

    <!-- End Page: pages/browse-procurements -->
</template>

<script>
import RequestHandler from "@/handler/RequestHandler";
import Breadcrumbs from "./breadcrumbs.vue";
import Alert from "./alert.vue";
import ProcurementsTable from "./procurements-table.vue";
import CustomSelect from "./custom-select.vue";
import {faCalendarDay} from "@fortawesome/pro-duotone-svg-icons";
import Preloader from "./preloader.vue";
import Pagination from "./pagination.vue";
import ContractsTable from "./contracts-table.vue";
import TagInput from "./tag-input.vue";
import Tooltip from "./tooltip";
import {ref, watch, computed} from "vue";
import { useEventsBus } from "@/helpers/eventBus";
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import {faMagnifyingGlass} from "@fortawesome/free-solid-svg-icons";
import ProcurementPieChart from "./procurement-pie-chart.vue";
import ContractPieChart from "./contract-pie-chart.vue";
import ContractsTableDate from "./contracts-table-date.vue";
import PositionBudgetTypeChart from "./position-budget-type-chart.vue";
import ContractFlyout from "./contract-flyout";
import VendorFlyout from "./vendor-flyout";
import { v4 as uuidv4 } from "uuid";
import TableauEmbed from "./tableau-embed.vue";

export default {

    name: "browse-contracts",
    data() {
        return {
            faCalendarDay: faCalendarDay,
            faSearch: faMagnifyingGlass,
        }
    },
    components: {
        PositionBudgetTypeChart,
        ContractsTableDate,
        ProcurementPieChart,
        ContractPieChart,
        TagInput,
        Tooltip,
        ContractsTable,
        Pagination,
        Preloader,
        CustomSelect,
        ProcurementsTable,
        Alert,
        Breadcrumbs,
        FontAwesomeIcon,
        ContractFlyout,
        VendorFlyout,
        TableauEmbed,
    },
    setup (props) {

        const uniqueId = computed(() => {
            return uuidv4();
        });

        const contractsInfo = ref([]);
        const contractsInfoLoading = ref(true);

        RequestHandler.loadContractsInfo().then(data => {
            contractsInfo.value = data;
            contractsInfoLoading.value = false;
        });

        const { bus } = useEventsBus();

        const contractFlyoutRef = ref(null);
        const vendorFlyoutRef = ref(null);

        const setAgencyFilter = (type, id) => {
            let filters = document.getElementById('reset-filters');
            filters?.click();

            let searchB = document.getElementById('dm' + type);
            searchB?.click();

            let itemSelect = document.getElementById(type + '-' + id);
            itemSelect.click();

            let table = document.getElementsByClassName('responsive-table');
            table[0].scrollIntoView();
            if(table.length > 0) {
                table[0].scrollIntoView();
            }
        }

        watch( () => bus.value.get('call-open-flyout-contract'),
            async (val) => {
                contractProps.value = null;
                if (contractFlyoutRef.value) {
                    try {
                        const contractRef = await contractFlyoutRef.value.openFlyout(val[0]);
                        contractProps.value = contractRef;
                        var x = document.getElementById("snackbar-vendor-contract");
                        x.classList.add('show');
                        x.classList.add('fadein');
                    } catch (error) {
                        console.error(error);
                    }
                }
            }
        );

        watch( () => bus.value.get('call-open-flyout-vendor'),
            async (val) => {
                vendorProps.value = null;
                if (vendorFlyoutRef.value) {
                    try {
                        const vendorRef = await vendorFlyoutRef.value.openFlyoutVendor(val[0]);
                        vendorProps.value = vendorRef;
                        var x = document.getElementById("snackbar-vendor");
                        x.classList.add('show');
                        x.classList.add('fadein');
                    } catch (error) {
                        console.error(error);
                    }
                }
            }
        );

        const contractProps = ref(null);

        const vendorProps = ref(null);

        return{
            path: [{title: 'Browse Contracts'}],
            contractsInfo,
            contractsInfoLoading,
            contractProps,
            contractFlyoutRef,
            vendorProps,
            vendorFlyoutRef,
            uniqueId,
            setAgencyFilter
        }
    }

}
</script>

<style lang="scss" scoped>
@import "../../assets/scss/_variables.scss";

.date-tabs {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .date-tabs-element{
        width: 600px;
    }
}
.filter-info {
    text-align: center;
    font-size: 21px;
    font-weight: 600;
}
.filter-input {
    .label {
        display: none;
    }
}
.inactive-sort {
    opacity: 0.45;
}
.active-sort {
    opacity: 1.0;
}

.search-cards {
    display: flex;
    margin-bottom: 10px;
    @media screen and (min-width: 1216px) {
       flex-direction: row;
        justify-content: space-between;
    }
    @media screen and (min-width: 961px) {
        flex-direction: row;
        justify-content: space-between;
    }
    @media screen and (max-width: 960px) {
        flex-direction: row;
        justify-content: space-between;

    }
    @media screen and (max-width: 768px) {
        flex-direction: column;
        .card {
            margin-bottom: 15px;
        }
    }
}
h3 {
    label {
        font-size: 1rem;
        line-height: 1.1rem;
        margin-bottom: 1em;
        padding-bottom: 10px;
        border-bottom: 1px solid $blue--med;
        &.sub-label {
            border-bottom: none;
            margin-bottom: 0px;
            padding-bottom: 0px;
        }
    }
}
.columns {
    margin-bottom: 0px;

    .column {
        padding-bottom: 0px;
    }

    .tbl thead th {
        color: #fff;
        position: -webkit-sticky;
        position: sticky;
    }
}
.date-range-card {
    .control {
        margin-bottom: 10px;
        display: flex;
        .control-icon {
            float: left;
            padding-right: 10px;
            color: $blue--dark;
            font-size: 28px;
        }
        .control-input {
            float: left;
            display: inline-block;
            position: relative;
            top: 7px;
        }
    }
    .control-title {
        display: block;
        width: 100%;
    }
}

</style>
