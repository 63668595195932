<template>
    <!-- Component: molecules/legislative-budget-requests -->
    <p class="title">Legislative Budget Requests</p>
    <div class="card">
        <div class="card-content">
            <div class="card-section" v-if="currentBudgetRequest || currentIssueAndDollar || currentAmendedBudget1 || currentAmendedBudget2 || currentAmendedBudget3 || currentAmendedBudget4 || currentAmendedBudget5">
                <p class="card-section__title">Fiscal Year 2025/2026</p>
                <span v-if="currentBudgetRequest">
                    <a :href="currentBudgetRequest" target="_blank" class="link">
                        <font-awesome-icon :icon="faCommentDollar" aria-label="Budget Request Submission icon"></font-awesome-icon>
                        <span class="title-text">Budget Request Submission</span>
                    </a>
                </span>
                <span v-if="currentIssueAndDollar">
                    <a :href="currentIssueAndDollar" target="_blank" class="link">
                        <font-awesome-icon :icon="faCoins" aria-label="Issue and Dollar Amount icon"></font-awesome-icon>
                        <span class="title-text">Issue and Dollar Amount</span>
                    </a>
                </span>
                <span v-if="currentAmendedBudget1">
                    <a :href="currentAmendedBudget1" target="_blank" class="link">
                       <font-awesome-icon :icon="faReceipt" aria-label="Amended Budget Request icon"></font-awesome-icon>
                        <span class="title-text">Amended Budget Request 1</span>
                    </a>
                </span>
                <span v-if="currentAmendedBudget2">
                    <a :href="currentAmendedBudget2" target="_blank" class="link">
                       <font-awesome-icon :icon="faReceipt" aria-label="Amended Budget Request icon"></font-awesome-icon>
                        <span class="title-text">Amended Budget Request 2</span>
                    </a>
                </span>
                <span v-if="currentAmendedBudget3">
                    <a :href="currentAmendedBudget3" target="_blank" class="link">
                       <font-awesome-icon :icon="faReceipt" aria-label="Amended Budget Request icon"></font-awesome-icon>
                        <span class="title-text">Amended Budget Request 3</span>
                    </a>
                </span>
                <span v-if="currentAmendedBudget4">
                    <a :href="currentAmendedBudget4" class="link">
                       <font-awesome-icon :icon="faReceipt" aria-label="Amended Budget Request icon"></font-awesome-icon>
                        <span class="title-text">Amended Budget Request 4</span>
                    </a>
                </span>
                <span v-if="currentAmendedBudget5">
                    <a :href="currentAmendedBudget5" class="link">
                       <font-awesome-icon :icon="faReceipt" aria-label="Amended Budget Request icon"></font-awesome-icon>
                        <span class="title-text">Amended Budget Request 5</span>
                    </a>
                </span>
            </div>

            <hr />

            <div class="card-section" v-if="priorBudgetRequest || priorIssueAndDollar || priorAmendedBudget1 || priorAmendedBudget2 || priorAmendedBudget3 || priorAmendedBudget4 || priorAmendedBudget5">
                <p class="card-section__title">Fiscal Year 2024/2025</p>
                <span v-if="priorBudgetRequest">
                    <a :href="priorBudgetRequest" target="_blank" class="link">
                        <font-awesome-icon :icon="faCommentDollar" aria-label="Budget Request Submission icon"></font-awesome-icon>
                        <span class="title-text">Budget Request Submission</span>
                    </a>
                </span>
                <span v-if="priorIssueAndDollar">
                    <a :href="priorIssueAndDollar" target="_blank" class="link">
                        <font-awesome-icon :icon="faCoins" aria-label="Issue and Dollar Amount icon"></font-awesome-icon>
                        <span class="title-text">Issue and Dollar Amount</span>
                    </a>
                </span>
                <span v-if="priorAmendedBudget1">
                    <a :href="priorAmendedBudget1" target="_blank" class="link">
                        <font-awesome-icon :icon="faReceipt" aria-label="Amended Budget Request icon"></font-awesome-icon>
                        <span class="title-text">Amended Budget Request 1</span>
                    </a>
                </span>
                <span v-if="priorAmendedBudget2">
                    <a :href="priorAmendedBudget2" target="_blank" class="link">
                        <font-awesome-icon :icon="faReceipt" aria-label="Amended Budget Request icon"></font-awesome-icon>
                        <span class="title-text">Amended Budget Request 2</span>
                    </a>
                </span>
                <span v-if="priorAmendedBudget3">
                    <a :href="priorAmendedBudget3" target="_blank" class="link">
                        <font-awesome-icon :icon="faReceipt" aria-label="Amended Budget Request icon"></font-awesome-icon>
                        <span class="title-text">Amended Budget Request 3</span>
                    </a>
                </span>
                <span v-if="priorAmendedBudget4">
                    <a :href="priorAmendedBudget4" target="_blank" class="link">
                        <font-awesome-icon :icon="faReceipt" aria-label="Amended Budget Request icon"></font-awesome-icon>
                        <span class="title-text">Amended Budget Request 4</span>
                    </a>
                </span>
                <span v-if="priorAmendedBudget5">
                    <a :href="priorAmendedBudget5" target="_blank" class="link">
                        <font-awesome-icon :icon="faReceipt" aria-label="Amended Budget Request icon"></font-awesome-icon>
                        <span class="title-text">Amended Budget Request 5</span>
                    </a>
                </span>
            </div>
        </div>
    </div>

    <iframe-modal
        v-if="showIframeDetails"
        @close="showIframeDetails= null"
        @created="newIframeCreated"
        :sourceURL="showIframeDetails.url"
        :sourceTitle="showIframeDetails.title"
        :title="showIframeDetails.title"
    ></iframe-modal>

    <!-- End Component: molecules/legislative-budget-requests -->
</template>

<script>
    import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
    import { faCommentDollar, faCoins, faReceipt } from '@fortawesome/free-solid-svg-icons';
    import IframeModal from "./iframe-modal";
    import {ref} from "vue";

    export default {
        name: 'Legislative Budget Requests',
        components: {
            FontAwesomeIcon,
            IframeModal
        },
        data() {
            return {
                faCommentDollar: faCommentDollar,
                faCoins: faCoins,
                faReceipt: faReceipt
            };
        },
        mounted() {
        },
        methods: {
        },
        props: [
            "currentBudgetRequest", "currentIssueAndDollar", "currentAmendedBudget1",
            "currentAmendedBudget2", "currentAmendedBudget3", "currentAmendedBudget4",
            "currentAmendedBudget5", "priorBudgetRequest", "priorIssueAndDollar",
            "priorAmendedBudget1", "priorAmendedBudget2", "priorAmendedBudget3",
            "priorAmendedBudget4", "priorAmendedBudget5"
        ],
        setup() {
            const showIframeDetails = ref(null);
            return {
                showIframeDetails
            };
        }
    }
</script>


<style lang="scss" scoped>
    @import "../../assets/scss/_variables.scss";
    @import "../../assets/scss/mixins/_bemify.scss";

    .title {
        font-weight: 600;
        font-family: Fira Sans,sans-serif;
        font-size: 28px;
        margin-bottom: 0.5em;
    }

    .card-section {
        padding-bottom: 10px;
        a {
            display: block;
            font-size: 18px;
            svg {
                color: $blue--dark;
                font-size: 32px;
                width: 40px;
                text-align: center;
            }
        }
        span {
            position: relative;
            top: -7px;
        }
    }
    .card-section__title {
        color: #022c43;
        font-size: 24px;
        font-weight: 600;
        margin-bottom: 15px;
    }

    .link {
        margin-bottom: 15px;
        svg {
            float: left;
            padding-right: 10px;
        }
        span.title-link {
            padding-left: 0px;
        }
    }

</style>
