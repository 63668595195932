<template>
    <!-- Component: organisms/document-info-card -->
    <info-card :document="$props">
        <div class="card-content">
            <div class="section">
                <div class="content">

                    <p class="card__info__label top">Document</p>
                    <div class="card__info__title">{{ title }}</div>

                     <div class="columns mt-15">
                        <div class="column" v-if="agency">
                            <p class="card__info__label card__info__label__with-tag">Agency</p>
                            <tag-agency :tag-text="agency.name" :tag-url="agency.url"/>
                        </div>

                        <div class="column" v-if="year">
                            <p class="card__info__label">Fiscal Year</p>
                            <p class="card__info__data">{{ year + " - " + (Number.parseInt(year) + 1) }}</p>
                        </div>

                        <div class="column" v-if="category">
                            <p class="card__info__label">Type</p>
                            <p class="card__info__data" v-html="highlightedCategory"></p>
                        </div>

                        <div class="column last_block"  v-if="fileUrl">
                            <p class="card__info__label">File</p>

                            <div v-if="highlight" class="card__info__data">
                                <!--button class="button document-prev-button" :id="'document-prev-button-' + contentId">Previous match</button>
                                <button class="button document-next-button" :id="'document-next-button-' + contentId">Next match</button-->
                                <div class="document-search-location">
                                    <template v-if="exact">
                                        <span :id="'document-search-total-count-' + contentId">{{ getHighlights(highlight).length }}</span> match<template v-if="getHighlights(highlight).length != 1">es</template>
                                    </template>
                                    <template v-else>
                                        <span :id="'document-search-total-count-' + contentId">Matches Found</span>
                                    </template>
                                </div>
                            </div>

                            <button class="button view-btn" @click="openModal($event)">
                                View document
                            </button>

                            <div class="card__info__data">
                                <div :class="'accordion-content accordion-content-' + contentId + ' block item' + contentId">
                                    <div class="container">
                                        <div class="columns">
                                            <div class="column">
                                                <div class="faq-content">
                                                    <div :id="'modal-' + contentId" :class="'modal document-modal' + (isModalOpen ? ' is-active' : '')">
                                                        <div class="modal-background" @click="closeModal"></div>
                                                        <div class="modal-card">
                                                            <header class="modal-card-head">
                                                                <p class="modal-card-title">{{ title }}</p>
                                                                <button class="close-item" aria-label="close" @click="closeModal"></button>
                                                            </header>
                                                            <section class="modal-card-body">
                                                                <div class="modal-card-content">
                                                                    <PDFViewerSearch v-if="isModalOpen" />
                                                                    <div class="adobe-dc-view-search"
                                                                        :id="'adobe-dc-view-' + contentId"
                                                                        :data-previd="'document-prev-button-' + contentId"
                                                                        :data-nextid="'document-next-button-' + contentId"
                                                                        :data-indexid="'document-search-index-' + contentId"
                                                                        :data-totalcountid="'document-search-total-count-' + contentId"
                                                                        :data-url="fileUrl"
                                                                        :data-filename="titleize(title)"
                                                                        :data-embedmode="'SIZED_CONTAINER'"
                                                                        :data-searchterm="searchTerm"
                                                                        :data-exact="exact"
                                                                        style="height: 500px; width: 100%;"
                                                                    ></div>
                                                                </div>
                                                            </section>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </info-card>
    <!-- End Component: organisms/procurement-info-card -->
</template>

<script>
    import { reactive, ref, computed } from 'vue';
    import { useStore } from "vuex";
    import InfoCard from "./info-card"
    import ReadMoreLink from "./read-more-link"
    import { priceFormat } from "@/helpers/filter";
    import TagVendor from "./tag--vendor";
    import TagAgency from "./tag--agency";
    import Tag from "./tag";
    import TagBudget from "./tag--budget";
    import IframeModal from "./iframe-modal";
    import Tooltip from "./tooltip";
    import Faqs from "./faqs";
    import PDFViewerSearch from "./pdf-viewer-search";

    export default {
        name: 'ProcurementInformationCard',
        components: {
            InfoCard,
            ReadMoreLink,
            TagVendor,
            TagAgency,
            Tag,
            TagBudget,
            IframeModal,
            Tooltip,
            Faqs,
            PDFViewerSearch,
        },
        props: {
            searchTerm: String,
            contentId: Number,
            locationId: Number,
            title: String,
            type: String,
            id: String,
            year: String,
            agency: Object,
            category: String,
            fileUrl: String,
            highlight: String,
            exact: Boolean,
        },
        data() {
            return {
                modalClassPresent: false
             };
        },
        computed: {
            highlightedCategory() {
                if (this.category && this.category.includes('Amended')) {
                return this.category.replace('Amended', '<span class="amended-highlight">Amended</span>');
                } else {
                return this.category;
                }
            }
        },
        setup(props) {
            const showIframe = ref(false);
            const store = useStore();
            const definitions = computed(() => store.getters.definitions);
            const bodyElm = document.body;

            const newIframeCreated = () => {
                showIframe.value = false;
            };

            let isPopStateListenerAdded = false;

            const isModalOpen = ref(false);
            const openModal = (ev) => {

                if (!isPopStateListenerAdded) {
                    window.addEventListener('popstate', closeModal);
                    isPopStateListenerAdded = true;
                }

                ev.preventDefault();
                isModalOpen.value = true;

                // Add hash to the URL
                if (history.pushState) {
                    history.pushState(null, null, '#pdfView');
                } else {
                    location.hash = 'pdfView';
                }

                // prevent scrolling while keeping user's scroll position
                const scrollY = window.scrollY;
                document.body.style.overflow = 'hidden';
                document.body.style.position = 'fixed';
                document.body.style.top = `-${scrollY}px`;

                window.searchDataId = 'adobe-dc-view-' + props.contentId;
                // on left arrow, call searchPrevious
                // on right arrow, call searchNext
                window.searchPrevListener = function(e) {
                    if (e.keyCode == 37) {
                        window.searchPrevious(window.searchDataId);
                    }
                    if (e.keyCode == 39) {
                        window.searchNext(window.searchDataId);
                    }
                };
                window.addEventListener('keydown', window.searchPrevListener);
            };
            const closeModal = () => {
                isModalOpen.value = false;

                // undo the scroll prevention
                document.body.style.overflow = '';
                document.body.style.position = '';
                // find the desired scroll position and make sure the user is put there
                const scrollY = -parseInt(document.body.style.top);
                document.body.style.top = '';
                window.scrollTo(0, scrollY);

                window.removeEventListener('keydown', window.searchPrevListener);
            };

            return {
                priceFormat,
                newIframeCreated,
                showIframe,
                definitions,
                isModalOpen,
                openModal,
                closeModal,
            };
        },
        mounted: function () {
            this.columnize();

            if (window.location.hash === '#pdfView') {
                // Remove the hash from the URL
                if (history.replaceState) {
                    history.replaceState(null, null, ' '); // Removes the hash without reloading the page
                } else {
                    // Fallback for older browsers
                    window.location.hash = '';
                }
            }

            const observer = new MutationObserver(mutations => {
                mutations.forEach(mutation => {
                    if (mutation.type === 'attributes' && mutation.attributeName === 'class') {
                        const body = document.querySelector('body')
                        this.modalClassPresent = body.classList.contains('ViewSDK_hideOverflow')
                    }
                })
            })
            const options = {
                attributes: true
            }
            const body = document.querySelector('body');
            observer.observe(body, options)
        },
        watch: {
            modalClassPresent(newVal) {
                if (!newVal) {
                    this.closeModal();
                }
            }
        },
        methods: {
            triggerAccordion(ev) {
                let contentId = this.contentId;

                // array of all triggers
                let triggers = Array.from(document.querySelectorAll('.accordion-' + contentId + ' [data-toggle="collapse"]'));
                // the button triggered
                const elm = ev.target;

                // find all the triggerable items
                const items = Array.from(document.querySelectorAll('.accordion-' + contentId + ' .accordion-btn'));
                items.forEach(target => {
                    // if the button is is the same as the one just triggered
                    if (target === elm) {
                        // skip this one, it is the one triggered
                    } else { // otherwise
                        // close the item
                        target.nextElementSibling.classList.remove('show');
                        // reset the icons on the button
                        target.querySelector('.header-icon--closed').classList.add('shown');
                        target.querySelector('.header-icon--open').classList.remove('shown');
                    }
                });

                // switch icons in the button
                var openIcon = elm.querySelector('.accordion-' + contentId + ' .header-icon--closed');
                var closeIcon = elm.querySelector('.accordion-' + contentId + ' .header-icon--open');
                closeIcon.classList.toggle('shown');
                openIcon.classList.toggle('shown');

                // if the button triggered is inside of the array of all the triggers (this should always be true)
                if (triggers.includes(elm)) {
                    // get the content related to the trigger button
                    const selector = elm.getAttribute('data-target');
                    const targets = Array.from(document.querySelectorAll(selector));

                    const fnmap = {
                        'toggle': 'toggle',
                        'show': 'add',
                        'hide': 'remove'
                    };

                    // open the selected one
                    targets.forEach(target => {
                        target.classList[fnmap['toggle']]('show');
                    });
                }
            },
            resetAccordions() {
                let contentId = this.contentId;

                // find all the triggerable items
                const items = Array.from(document.querySelectorAll('.accordion-' + contentId + ' .accordion-btn'));
                items.forEach(target => {
                    // close the item
                    target.nextElementSibling.classList.remove('show');
                    // reset the icons on the button
                    target.querySelector('.accordion-' + contentId + ' .header-icon--closed').classList.add('shown');
                    target.querySelector('.accordion-' + contentId + ' .header-icon--open').classList.remove('shown');
                });
            },
            getHighlightItems: function (highlights) {
                let highlightsSplit = this.getHighlights(highlights);
                let items = [];
                for (let i = 0; i < highlightsSplit.length; i++) {
                    let item = {
                        name: 'Match ' + (i + 1),
                        children: [
                            {
                                content: highlightsSplit[i],
                            },
                        ],
                    };
                    items.push(item);
                }
                return items;
            },
            getHighlights: function (highlight) {
                return highlight.split('~MYGOVGUIDE_ENDOFMATCH~');
            },
            titleize: function(item) {
                if (!item) {
                    return item;
                }
                return item.toLowerCase().replace(/(?:^|\s|-)\S/g, x => x.toUpperCase());
            },
            columnize: function() {

                // forEach function
                var forEach = function (array, callback, scope) {
                    for (var i = 0; i < array.length; i++) {
                    callback.call(scope, i, array[i]); // passes back stuff we need
                    }
                }

                // select all .parent divs
                var parentDivs = document.querySelectorAll('.parent');

                //slicing the array
                var chunk = function ( array, size ) {
                    var arr = [];
                    for ( var i = 0; i < array.length; i += size ) {
                        var newSlicedArray = Array.prototype.slice.call( array, i, i + size );
                        arr.push(newSlicedArray);
                    }
                    return arr;
                }

                //run foreach function
                forEach(parentDivs, function (index, value) {

                    var childrens = value.querySelectorAll(".create-rows");

                    var final = chunk(childrens,4);
                    final.map( towrap =>
                        towrap.reduce( (acc, el) =>
                            (acc.appendChild(el),acc) , document.createElement('div') )
                    ).forEach( el => {
                        el.className ="columns created-cols";
                        value.appendChild(el)
                    })

                });

            }
        }
    }
</script>

<style lang="scss" scoped>
    @import "../../assets/scss/_variables.scss";
    @import "../../assets/scss/mixins/_bemify.scss";
    @import "../../assets/scss/partials/_accordion-checkbox.scss";

    .modal.document-modal {
        display: none;
        z-index: 999999999;
        &.is-active {
            display: block;
        }
    }

    .header-icon {
        display: none;
        &.shown {
            display: inline-block;
        }
    }

    .header-title, .header-icon, .header-icon--open {
        pointer-events: none;
    }

    .header-title {
        display: inline-block;
        font-weight: 600;
        font-size: 28px;
        margin-left: 10px;
        margin-bottom: 0px;
        position: relative;
        top: -5px;
    }

    .last_block {
      max-width: 25%;
    }
    .modal-card-head, .modal-card-foot {
        border: none;
        background-color: white;
    }

    .modal-card-head {
        padding: 10px !important;
    }

    .modal-card-body {
        border-top: 1px solid $grey--dark;
        padding: 0px;
    }

    .modal-card-title {
        color: $blue--dark;
        font-size: 24px;
        margin-bottom: 0px !important;
        font-weight: 600;
        flex-shrink: initial !important;
        line-height: 1.4em !important;
        &.modal-card-title__name {
            font-weight: 600;
            color: $blue--dark;
            font-size: 28px;
            margin-bottom: 30px;
        }
    }
    .modal-card {
        width: 100%;
        height: 100%;
    }

    .mb-0 {
        margin-bottom: 0px !important;
    }

    .content .card__info__title {
        margin-top: 0.75em !important;
        font-family: $header;
        font-weight: 600;
        font-size: 2em;
    }

    @include block('card') {
        .media {
            display: contents;
        }
        @include element('info') {
            @include element('label') {
                float: left;
                margin-right: 10px;
                display: block;
                width: 100%;
                margin-bottom: 5px !important;
                &.top {
                    margin-bottom: 0px !important;
                }
            }
            @include element('description') {
                line-height: 1.7em;
            }
        }
    }
     .level {
        margin-bottom: 15px;
        justify-content: start;
        display: flow-root;
        .level-item {
            display: inline-flex !important;
            max-width: 200px;
            width: auto;
            margin-right: 40px;
            margin-bottom: 15px;
            .title {
                font-size: 1.25rem;
            }
            .end-date {
                max-width: 250px;
            }
        }
    }

    .card__info__label.card__info__label__link {
        font-size: 15px;
    }

    .card__info__data--highlight {
        margin-bottom: 0em;
    }

    .header-icon {
        display: none;
        &.shown {
            display: inline-block;
        }
    }

    .header-title, .header-icon, .header-icon--open {
        pointer-events: none;
    }

    .header-title {
        display: inline-block;
        font-weight: 600;
        font-size: 28px;
        margin-left: 10px;
        margin-bottom: 0px;
        position: relative;
        top: -5px;
    }

    .faq-item__container {
        margin-top: 15px;
        margin-bottom: 15px;
    }

    .faq-item {
        border: 3px solid $blue--med;
        width: 100%;
        transition: all 0.3s ease-in-out;
        background-color: $white;
        border-radius: 6px;
        padding-bottom: 10px;
        margin-bottom: 15px;
        .accordion-label {
            .header-icon, .header-icon--open {
                padding-right: 15px;
            }
            .header-title {
                font-size: 28px;
                margin-bottom: 0px;
            }
        }
        .accordion-content {
            padding-top: 0px;
            .column {
                padding-top: 0px;
            }
        }
    }

    .faq-content {
        padding: 20px;
    }

    .collapse {
        display: block;
        max-height: 0px;
        overflow: hidden;
        transition: max-height .5s cubic-bezier(0, 1, 0, 1);

        &.show {
            max-height: 99em;
            transition: max-height .5s ease-in-out;
        }
    }

    .accordion-btn {
        background-color: transparent;
        border: none;
        margin-top: 15px;
    }

    .card__info__data .icon {
        margin-right: 10px;
    }

    pre b {
        background-color: $blue--med;
        color: white;
    }
    .hidden {
        display: none;
    }

    .close-item {
        position: absolute;
        right: 10px;
        background: none;
        border: none;
        padding-bottom: 0px;
        &:before {
            font-size: 30px;
            cursor: pointer;
            content: "×";
            color: $blue--dark;
            transition: color .3s;
            font-weight: 600;
            margin-left: 10px;
            position: relative;
            float: right;
            top: -5px;
            left: -5px;
        }
    }
    .card__info__data p:last-of-type.modal-card-title {
        margin-bottom: 0px !important;
    }

    button.button.view-btn {
        &:focus, &:hover, &:active {
            color: $white !important;
        }
    }

    .adobe-dc-view-search {
        height: 88vh !important;
        > iframe {
            height: 88vh !important;
        }
    }

    .amended-highlight {
        color: $red;
    }
</style>
